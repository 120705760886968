import React from "react";
import { useTranslation } from "react-i18next";

const TermsCondicions = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-[69px] lg:mt-[75px]">
      <div className="py-8 lg:py-10 px-6 lg:px-[100px] bg-gradient-to-r from-[#AD98FF] to-[#612DFE] text-white text-center">
        <h1 className="text-[24px] lg:text-[32px] font-bold">{t("Terms & Conditions")}.</h1>
        <p className="mt-2 lg:mt-4 text-[14px]">{t("Know the terms rules and guidelines for using DeFily")}</p>
      </div>

      <div className="mx-6 lg:mx-[100px] pt-8 lg:pt-[80px] pb-4 lg:pb-12 mb-4 lg:mb-12 flex flex-col lg:flex-row justify-center border-b border-solid border-[#DBDFE4]">
        <div className="w-full lg:w-3/5 mb-4 lg:mb-0">
          <div className="text-[14px] lg:text-[16px] text-[#0E0E33] pt-0 lg:pt-7">
            <div className="text-[#7A2FF4] font-bold mb-2 lg:mb-7 ">
              <p className="">{t("Terms and Conditions for")} DeFily.ai </p>
              <p className="italic">{t("EFFECTIVE DATE")}: 04/08/2024</p>
            </div>

            <p className="mb-6">{t("welcomeToDefily Terms and Conditions")}</p>
            <p className="">{t("by using Defily Terms and Conditions")}</p>
          </div>
        </div>

        <div className="bg-[#F5F7FC] text-[#0E0E33] rounded-2xl py-4 px-6 ml-0 lg:ml-[80px] w-full lg:w-2/5">
          <p className="mb-2 lg:mb-4 font-bold text-[16px]">{t("Table of Contents")}:</p>

          <ul className="text-[14px]">
            <li className="mb-1">{t("Acceptance of Terms")}</li>
            <li className="mb-1">{t("Eligibility")}</li>
            <li className="mb-1">{t("Account Activation and Membership")}</li>
            <li className="mb-1">{t("Crypto Staking and Risks")}</li>
            <li className="mb-1">{t("Account Security and Wallets")}</li>
            <li className="mb-1">{t("Affiliate Program")}</li>
            <li className="mb-1">{t("No Guarantee of Results")}</li>
            <li className="mb-1">{t("Service Updates and Changes")}</li>
            <li className="mb-1">{t("Disclaimer of Liability")}</li>
            <li className="mb-1">{t("Taxes and Jurisdiction")}</li>
            <li>{t("Contact Us")}</li>
          </ul>
        </div>
      </div>

      <div className=" text-[#1E0E39] text-[14px] lg:text-[16px] px-6 lg:px-[100px]">
        <div className="pb-4 lg:pb-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">1</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px] ">{t("Acceptance of Terms")}</span>
          </div>
          <p className="mt-4">{t("By using our website and services")}</p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">2</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Eligibility")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Age Requirement")}:</span> {t("You must be at least 18 years old to use our services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Geographic Restrictions")}:</span> {t("Residents of the United States North Korea and other blacklisted")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">3</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Account Activation and Membership")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Account Activation Fee")}:</span> {t("To activate your DeFily account NFT")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Membership Plans")}:</span> {t("We offer both yearly membership plans")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">4</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Crypto Staking and Risks")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Staking")}:</span> {t("When you stake your cryptocurrency with DeFily")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Responsibility for Losses")}:</span>{" "}
            {t("You are solely responsible for any losses resulting from your staking activities")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">5</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Account Security and Wallets")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Wallet Connection")}:</span>{" "}
            {t("You are required to connect your own crypto wallet to your DeFily account")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Account Responsibility")}:</span>{" "}
            {t("You are solely responsible for maintaining the security of your account and wallet")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">6</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Affiliate Program")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Affiliate Program")}:</span> {t("Participation in our affiliate program is optional")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Cross-Recruiting Restriction")}:</span>{" "}
            {t("Cross-recruiting or the recruitment of individuals who are already affiliated with another member or affiliate within DeFily")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">7</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("No Guarantee of Results")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("No Guarantees")}:</span>{" "}
            {t("We do not guarantee any specific financial outcomes or results from using our services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Performance Fees")}:</span>{" "}
            {t("Profits from trading are subject to performance fees as detailed in your membership plan")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">8</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Service Updates and Changes")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Service Updates")}:</span> {t("DeFily reserves the right to update")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">9</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Disclaimer of Liability")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Financial Losses")}:</span>{" "}
            {t("DeFily is not responsible for any financial losses or damages incurred from trading activities or reliance on our services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("DeFi Risks")}:</span> {t("Cryptocurrency and DeFi trading involve high risk and market volatility")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">10</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Taxes and Jurisdiction")}</span>
          </div>
          <p className="mt-4">{t("You are solely responsible for any taxes applicable to your staking rewards")}</p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">11</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Contact Us")}</span>
          </div>
          <p className="mt-4">
            {t("For any questions or concerns regarding these Terms and Conditions")} <span className="font-bold">{t("Email:")} info@defily.ai</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsCondicions;
