import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card1 from "../../assets/imgs/businessNFT/card1.jpg";
import Card2 from "../../assets/imgs/businessNFT/card2.jpg";
import Card3 from "../../assets/imgs/businessNFT/card3.jpg";
import Card4 from "../../assets/imgs/businessNFT/card4.jpg";
import Card1Mobil from "../../assets/imgs/businessNFT/card1Mobil.jpg";
import Card2Mobil from "../../assets/imgs/businessNFT/card2Mobil.jpg";
import Card3Mobil from "../../assets/imgs/businessNFT/card3Mobil.jpg";
import Card4Mobil from "../../assets/imgs/businessNFT/card4Mobil.jpg";

const YourBusinessNFT = () => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // 1024px es el breakpoint 'lg' en Tailwind
    };

    handleResize(); // Establecer el estado inicial
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const listInfoCard = [
    {
      id: 1,
      title: t("Security"),
      fondoImg: Card1,
      fondoImgMobil: Card1Mobil,
      description: t("Your account is directly linked to your preferred"),
    },
    {
      id: 2,
      title: t("Safety WEB3 Verification"),
      fondoImg: Card2,
      fondoImgMobil: Card2Mobil,
      description: t("Keep your account commissions and other crucial business"),
    },
    {
      id: 3,
      title: t("Transparency"),
      fondoImg: Card3,
      fondoImgMobil: Card3Mobil,
      description: t("All DeFily transactions are noted on the blockchain"),
    },
    {
      id: 4,
      title: t("Transferable"),
      fondoImg: Card4,
      fondoImgMobil: Card4Mobil,
      description: t("Your entire DeFily business is minted as an NFT making it simple to transfer"),
    },
  ];

  return (
    <div className="px-6 lg:px-[100px] py-8 lg:py-[100px]">
      <h1 className="bg-gradient-to-r from-[#AD98FF] to-[#612DFE] text-transparent bg-clip-text  text-[24px] lg:text-[32px] text-center font-bold mb-4 lg:mb-12">
        {t("Your business as an NFT")}
      </h1>

      <div className="flex flex-col lg:flex-row justify-center">
        {listInfoCard.map((info) => (
          <div
            key={info.id}
            className="w-full lg:w-[255px] h-[180px] lg:h-[325px] flex flex-col justify-end p-6 rounded-2xl bg-cover bg-center mr-0 lg:mr-6 last:mr-0 mb-4 lg:mb-0 last:mb-0"
            // style={{
            //   backgroundImage: `url(${info.fondoImg})`,
            // }}
            style={{
              backgroundImage: `url(${isMobile ? info.fondoImgMobil : info.fondoImg})`,
            }}
          >
            <h1 className="text-[20px] text-white font-bold mb-2 leading-5">{info.title}</h1>
            <p className="text-[14px] text-[#F2F3F8]">{info.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default YourBusinessNFT;
