import React from "react";

const ButtonGetStarted = ({ text, classContainer = "", classButton = "", onClickFn = () => {}, linkRedirect = "" }) => {
  return (
    <div className={`rounded-2xl border border-solid border-[#AD98FF] p-2 ${classContainer}`} onClick={onClickFn}>
      <a href={linkRedirect} target="_blank" rel="noreferrer">
        <button
          className={`${
            classButton
              ? classButton
              : "rounded-[10px] bg-gradient-to-r from-[#AD98FF] to-[#612DFE] px-4 text-[#ffffff] w-[110px] lg:w-[125px] h-[32px] lg:h-[40px] text-[12px] lg:text-[16px]"
          } `}
        >
          {text}
        </button>
      </a>
    </div>
  );
};

export default ButtonGetStarted;
