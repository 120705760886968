import React from "react";
import { useTranslation } from "react-i18next";
import FlexMember from "../../assets/imgs/flexMember.gif";
import InteStaking from "../../assets/imgs/inteStaking.gif";
import AutoTrading from "../../assets/imgs/autoTrading.gif";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div
      id="#aboutUs"
      className="px-6 lg:px-[100px] py-[32px] lg:py-[100px] flex flex-col lg:flex-row items-center lg:items-start justify-between relative"
    >
      <div className="mr-0 lg:mr-12 mb-4 lg:mb-0 w-full lg:w-2/6">
        <h1 className="bg-gradient-to-r from-[#AD98FF] to-[#612DFE] text-transparent bg-clip-text text-[24px] lg:text-[32px] font-bold">
          {t("Get to know us")}.
        </h1>
        <div className="text-[14px] text-[#0E0E33] mt-2 lg:mt-4">
          <span>{t("At DeFily our goal is to lead the DeFi revolution by providing accessible and secure tools for all cryptocurrency users")}</span>
          <span className="font-bold ml-1">{t("We are committed to providing a transparent")}</span>
        </div>
      </div>

      <div className="w-full lg:w-3/6">
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
          <div className="w-[60px] lg:w-[200px] h-[60px] lg:h-[100px]">
            <img src={InteStaking} alt="Intelligent Staking." className="w-full h-full" />
          </div>
          <div>
            <h1 className="bg-gradient-to-r from-[#AD98FF] to-[#612DFE] text-transparent bg-clip-text text-[16px] lg:text-[20px] font-bold">
              {t("Intelligent Staking")}.
            </h1>
            <p className="mt-2 text-[14px] text-[#0E0E33]">{t("Earn variable returns")}</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between my-4 lg:my-12">
          <div className="w-[60px] lg:w-[200px] h-[60px] lg:h-[100px]">
            <img src={AutoTrading} alt="Automated Trading with AI." className="w-full h-full" />
          </div>
          <div>
            <h1 className="bg-gradient-to-r from-[#AD98FF] to-[#612DFE] text-transparent bg-clip-text text-[16px] lg:text-[20px] font-bold">
              {t("Automated Trading with AI")}.
            </h1>
            <p className="mt-2 text-[14px] text-[#0E0E33]">{t("DeFily combines artificial intelligence with DeFi protocols")}</p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
          <div className="w-[60px] lg:w-[200px] h-[60px] lg:h-[100px]">
            <img src={FlexMember} alt="Flexible Memberships." className="w-full h-full" />
          </div>
          <div>
            <h1 className="bg-gradient-to-r from-[#AD98FF] to-[#612DFE] text-transparent bg-clip-text text-[16px] lg:text-[20px] font-bold">
              {t("Flexible Memberships")}.
            </h1>
            <p className="mt-2 text-[14px] text-[#0E0E33]">{t("Choose from different membership plans according to your investment needs")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
