import Layout from "./components/Layout";

// importaciones para el cambio de idioma
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import esTranslation from "./i18n/es.json";
import enTranslation from "./i18n/en.json";
import frTranslation from "./i18n/fr.json";
import itTranslation from "./i18n/it.json";
import ptTranslation from "./i18n/pt.json";
import arTranslation from "./i18n/ar.json";
import faTranslation from "./i18n/fa.json";
import hiTranslation from "./i18n/hi.json";
import jaTranslation from "./i18n/ja.json";
import koTranslation from "./i18n/ko.json";
import ruTranslation from "./i18n/ru.json";
import thTranslation from "./i18n/th.json";
import trTranslation from "./i18n/tr.json";
import urTranslation from "./i18n/ur.json";
import viTranslation from "./i18n/vi.json";
import zhTranslation from "./i18n/zh.json";

/** Configuracion del cambio de idioma */
let getLanguageStore = localStorage.getItem("language");

i18next.init({
  interpolation: { escapeValue: false },
  lng: getLanguageStore,
  fallbackLng: "en",
  resources: {
    es: {
      translation: esTranslation,
    },
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    it: {
      translation: itTranslation,
    },
    pt: {
      translation: ptTranslation,
    },
    ar: {
      translation: arTranslation,
    },
    fa: {
      translation: faTranslation,
    },
    hi: {
      translation: hiTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
    ko: {
      translation: koTranslation,
    },
    ru: {
      translation: ruTranslation,
    },
    th: {
      translation: thTranslation,
    },
    tr: {
      translation: trTranslation,
    },
    ur: {
      translation: urTranslation,
    },
    vi: {
      translation: viTranslation,
    },
    zh: {
      translation: zhTranslation,
    },
  },
});

function App() {
  return (
    <div className="App poppins-regular">
      <I18nextProvider i18n={i18next}>
        <Layout />
      </I18nextProvider>
    </div>
  );
}

export default App;
