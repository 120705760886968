import React from "react";
import Logo from "../../assets/imgs/Logo.png";
import { Link, useLocation } from "react-router-dom";
import { Link as LinkScroll } from "react-scroll";
import { useTranslation } from "react-i18next";
import ButtonGetStarted from "./ButtonGetStarted";
import ContainerLanguage from "./ContainerLanguage";

const Navbar = () => {
  const { t } = useTranslation();
  const pathname = useLocation().pathname;

  return (
    <div
      className={`navbar bg-gradient-to-t from-[#39307B] to-[#0E0E33]  border-b border-solid border-[#ffffff1a] px-6 lg:px-[100px] py-[10px] flex items-center justify-between fixed top-0 z-50 w-full`}
    >
      <div className="container-logo flex items-center">
        <Link to={"/"}>
          <img className="img-logo w-[85px] h-[22px] lg:w-[160px] lg:h-[40px]" src={Logo} alt="Logo" />
        </Link>
        {pathname !== "/" ? (
          <div className=" text-white ml-2 hidden lg:block">
            <ContainerLanguage />
          </div>
        ) : null}

        <div className="text-white ml-2 lg:hidden">
          <ContainerLanguage />
        </div>
      </div>

      {pathname === "/" ? (
        <ul className=" justify-between items-center text-white text-[16px] gap-x-[48px] hidden lg:flex">
          <LinkScroll to={"#aboutUs"} spy={true} smooth={true} offset={-92} duration={500}>
            <p className="cursor-pointer">{t("About us")}</p>
          </LinkScroll>

          <LinkScroll to={"#launch"} spy={true} smooth={true} offset={-92} duration={500}>
            <p className="cursor-pointer">{t("Launch")}</p>
          </LinkScroll>
          <LinkScroll to={"#earnings"} spy={true} smooth={true} offset={-5} duration={500}>
            <p className="cursor-pointer">{t("Earnings")}</p>
          </LinkScroll>

          <ContainerLanguage />
        </ul>
      ) : null}

      <ButtonGetStarted text={t("Get Started")} linkRedirect="https://app.defily.ai/" classContainer=" px-0 border-none" />
    </div>
  );
};

export default Navbar;
