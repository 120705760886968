import React from "react";
import { useTranslation } from "react-i18next";
import CoinsColors from "../../assets/imgs/monedasColors.png";
import ButtonGetStarted from "../generals/ButtonGetStarted";
import arosDark from "../../assets/imgs/arosFondoOscuro.png";

const WaysToEarn = () => {
  const { t } = useTranslation();

  return (
    <div
      className="bg-[#0E0E33] px-6 lg:px-[100px] py-8 lg:py-[100px] border-b-[2px] border-solid border-[#ffffff40] flex flex-col lg:flex-row relative overflow-hidden"
      id="#earnings"
    >
      <img
        src={arosDark}
        alt="Aros"
        className="absolute top-1/2 left-1/2 lg:-left-[250px] transform -translate-y-1/2 -translate-x-1/2 lg:translate-x-0 h-[450px] rotate-180"
      />
      <img src={arosDark} alt="Aros" className="absolute top-1/2 -right-[250px] transform -translate-y-1/2 h-[450px] hidden lg:block" />

      <div className="container-left mr-0 lg:mr-12 w-full lg:w-1/2 text-center lg:text-start">
        <h1 className="text-[24px] lg:text-[32px] font-bold bg-gradient-to-r from-[#20DABB] to-[#1FAC95] text-transparent bg-clip-text">
          {t("10 ways to earn on WEB3")}
        </h1>
        <p className="my-2 lg:my-4 text-[14px] text-white">{t("First ever fully transparent")}</p>
        <div className="flex items-center justify-center lg:justify-start">
          <ButtonGetStarted text={t("Get Started")} linkRedirect="https://app.defily.ai/" />
        </div>
        <div className="mt-4 lg:mt-6 mb-4 lg:mb-0">
          <img src={CoinsColors} alt="Coins" className="w-[300px] h-[280px] mx-auto lg:mx-0" />
        </div>
      </div>

      <div className="container-right w-full  lg:w-1/2">
        <div className="container1 p-4 rounded-2xl border border-solid border-[#ffffff40] flex flex-col lg:flex-row">
          <div className="w-full lg:w-1/2 mb-2 lg:mb-0">
            <p className="mb-1 lg:mb-4 text-[16px] lg:text-[20px] font-bold bg-gradient-to-r from-[#20DABB] to-[#1FAC95] text-transparent bg-clip-text">
              {t("Active Rewards")}
            </p>
            <span className="text-[14px] text-white">{t("These rewards are earned by leveraging your efforts")}</span>
          </div>
          <ul className="container-green p-2 rounded-lg bg-gradient-to-r from-[#20dabb40] to-[#20dabb00] text-[14px] text-[#20DABB] ">
            <li>{t("NFT Bonus")} (50%)</li>
            <li>{t("Direct Bonus")} (20%)</li>
            <li>{t("Binary Bonus")} (10%)</li>
            <li>{t("Startup Bonus")} (100%)</li>
          </ul>
        </div>

        <div className="container2 p-4 rounded-2xl border border-solid border-[#ffffff40] flex flex-col lg:flex-row my-8">
          <div className="w-full lg:w-1/2 mb-2 lg:mb-0">
            <p className="mb-1 lg:mb-4 text-[16px] lg:text-[20px] font-bold bg-gradient-to-r from-[#20DABB] to-[#1FAC95] text-transparent bg-clip-text">
              {t("Passive Rewards")}
            </p>
            <span className="text-[14px] text-white">{t("These rewards are earned by leveraging the DeFily DAPP")}</span>
          </div>
          <ul className="container-green p-2 rounded-lg bg-gradient-to-r from-[#20dabb40] to-[#20dabb00] text-[14px] text-[#20DABB] ">
            <li>{t("Staking Profit")}</li>
            <li>{t("Direct Rewards")} (20%)</li>
            <li>{t("Binary Rewards")} (10%)</li>
            <li>{t("Leadership Rewards")}</li>
          </ul>
        </div>

        <div className="container3 p-4 rounded-2xl border border-solid border-[#ffffff40] flex flex-col lg:flex-row ">
          <div className="w-full lg:w-1/2 mb-2 lg:mb-0">
            <p className="mb-1 lg:mb-4 text-[16px] lg:text-[20px] font-bold bg-gradient-to-r from-[#20DABB] to-[#1FAC95] text-transparent bg-clip-text">
              {t("Rank Rewards")}
            </p>
            <span className="text-[14px] text-white">{t("These rewards are earned by leveraging the efforts of your network")}</span>
          </div>
          <ul className="container-green p-2 rounded-lg bg-gradient-to-r from-[#20dabb40] to-[#20dabb00] text-[14px] text-[#20DABB] ">
            <li>{t("Cash Bonuses")}</li>
            <li>{t("Luxury Rewards")}</li>
            <li>{t("Trips to Exotic Destinations")}</li>
            <li>{t("Promotions")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WaysToEarn;
