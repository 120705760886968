import React from "react";
import Banners from "../components/home/Banners";
import AboutUs from "../components/home/AboutUs";
import RegisterTransmissions from "../components/home/RegisterTransmissions";
import GetReadyLaunch from "../components/home/GetReadyLaunch";
import DefilyDAPP from "../components/home/DefilyDAPP";
import YourBusinessNFT from "../components/home/YourBusinessNFT";
import WaysToEarn from "../components/home/WaysToEarn";
import LearnMore from "../components/home/LearnMore";
import GetInTouch from "../components/home/GetInTouch";

const Home = () => {
  return (
    <div>
      <Banners />
      <AboutUs />
      <RegisterTransmissions />
      <GetReadyLaunch />
      <DefilyDAPP />
      <YourBusinessNFT />
      <WaysToEarn />
      <LearnMore />
      <GetInTouch />
    </div>
  );
};

export default Home;
