import React from "react";
import { useTranslation } from "react-i18next";
import JoyaGif from "../../assets/imgs/joya.gif";
import Cohete from "../../assets/imgs/cohete.png";
import Coins from "../../assets/imgs/coinsUsdt.png";

const GetReadyLaunch = () => {
  const { t } = useTranslation();

  return (
    <div className="h-[480px] lg:h-[550px] relative" id="#launch">
      <div className="container-white h-3/5 px-6 lg:px-[100px]">
        <div className="flex flex-col lg:flex-row items-center justify-center my-4 lg:my-12">
          <img src={JoyaGif} alt="Joya" className="w-12 h-12" />
          <h1 className="ml-0 lg:ml-2 bg-gradient-to-r from-[#AD98FF] to-[#612DFE] text-transparent bg-clip-text text-[24px] lg:text-[32px] text-center lg:text-start font-bold">
            {t("Get ready for our Big Launch")}
          </h1>
        </div>
        <div className="flex items-center justify-center w-full absolute left-0">
          <img src={Cohete} alt="Cohete" className="w-[180px] hidden lg:block" />
          <div className="w-[90%] lg:w-[660px] h-[350px] lg:h-[410px] mx-8 rounded-[10px] aspect-video overflow-hidden">
            <iframe
              src="https://www.youtube.com/embed/GznmHjS90M4"
              className="w-full h-full"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video player"
            ></iframe>
          </div>
          <img src={Coins} alt="Coins" className="w-[180px] hidden lg:block" />
        </div>
      </div>

      <div className="container-purple bg-[#0E0E33] h-2/5 w-full px-6 lg:px-[100px] pb-8  lg:pb-[100px]"></div>
    </div>
  );
};

export default GetReadyLaunch;
