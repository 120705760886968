import React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-[69px] lg:mt-[75px]">
      <div className="py-8 lg:py-10 px-6 lg:px-[100px] bg-gradient-to-r from-[#DBDFE4] to-[#8C8F91] text-white text-center">
        <h1 className="text-[24px] lg:text-[32px] font-bold">{t("Privacy Policy")}.</h1>
        <p className="mt-2 lg:mt-4 text-[14px]">{t("This Privacy Policy is meant to help you understand what information we collect")}</p>
      </div>

      <div className="mx-6 lg:mx-[100px] pt-8 lg:pt-[80px] pb-4 lg:pb-12 mb-4 lg:mb-12 flex flex-col lg:flex-row justify-center border-b border-solid border-[#DBDFE4]">
        <div className="w-full lg:w-3/5 mb-4 lg:mb-0">
          <div className="text-[14px] lg:text-[16px] text-[#0E0E33] pt-0 lg:pt-7">
            <div className="text-[#A9AEB4] font-bold mb-2 lg:mb-7 ">
              <p className="">{t("Privacy Policy for")} DeFily.ai </p>
              <p className="italic">{t("EFFECTIVE DATE")}: 04/08/2024</p>
            </div>

            <p className="mb-6">{t("AtDefily Privacy Policy")}</p>
            <p className="">{t("By using you agree to this Privacy Policy")}</p>
          </div>
        </div>

        <div className="bg-[#F5F7FC] text-[#0E0E33] rounded-2xl py-4 px-6  ml-0 lg:ml-[80px] w-full lg:w-2/5">
          <p className="mb-2 lg:mb-4 font-bold text-[16px]">{t("Table of Contents")}:</p>

          <ul className="text-[14px]">
            <li className="mb-1">{t("Information We Collect")}</li>
            <li className="mb-1">{t("How We Use Your Information")}</li>
            <li className="mb-1">{t("Data Security Protection Measures")}</li>
            <li className="mb-1">{t("Sharing Your Information")}</li>
            <li className="mb-1">{t("Your Rights")}</li>
            <li className="mb-1">{t("Updates to This Policy")}</li>
            <li>{t("Contact Us")}</li>
          </ul>
        </div>
      </div>

      <div className=" text-[#1E0E39] text-[14px] lg:text-[16px] px-6 lg:px-[100px] ">
        <div className="pb-4 lg:pb-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">1</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Information We Collect")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Wallet Information")}:</span>{" "}
            {t("We access your wallet address and transaction data when you connect your crypto wallet to our platform")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Technical Data")}:</span> {t("We may collect data such as your IP address")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">2</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("How We Use Your Information")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Service Delivery")}:</span> {t("To provide and enhance our decentralized services")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Communication")}:</span> {t("To send updates or notifications related to your account and services")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">3</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Data Security Protection Measures")}</span>
          </div>
          <p className="mt-4">{t("We implement security measures to protect your data")}</p>
          <p className="mt-4">
            <span className="font-bold">{t("Decentralized Control")}:</span> {t("We do not control or store your wallet data directly")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">4</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Sharing Your Information")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Third Parties")}:</span> {t("Our services may interact with external Web3 protocols")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">5</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Your Rights")}</span>
          </div>
          <p className="mt-4">
            <span className="font-bold">{t("Manage Preferences")}:</span> {t("You can manage your cookie settings through your browser")}
          </p>
          <p className="mt-4">
            <span className="font-bold">{t("Data Control")}:</span> {t("You control your wallet and transaction data through your wallet provider")}
          </p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">6</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Updates to This Policy")}</span>
          </div>
          <p className="mt-4">{t("We may update this Privacy Policy periodically")}</p>
        </div>

        <div className="py-4 lg:py-7 border-b border-solid border-[#F2F3F8]">
          <div className="flex items-center">
            <p className="rounded-full w-[30px] h-[30px] bg-[#A9AEB4] text-white font-bold flex justify-center items-center mr-2">7</p>
            <span className="font-bold text-[#A9AEB4] text-[16px] lg:text-[20px]">{t("Contact Us")}</span>
          </div>
          <p className="mt-4">
            {t("For questions about this Privacy Policy please contact us at")} <span className="font-bold">{t("Email:")}: info@defily.ai</span>
          </p>
          <p className="mt-4">{t("By using you agree to this Privacy Policy")}</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
