import React from "react";
import { useTranslation } from "react-i18next";
import arosDark from "../../assets/imgs/arosFondoOscuro.png";
import Phones from "../../assets/imgs/theDefilyDapp/telefonos.png";
import Card1 from "../../assets/imgs/theDefilyDapp/card1.jpg";
import Card2 from "../../assets/imgs/theDefilyDapp/card2.jpg";
import Card3 from "../../assets/imgs/theDefilyDapp/card3.jpg";
import Card4 from "../../assets/imgs/theDefilyDapp/card4.jpg";
import Card5 from "../../assets/imgs/theDefilyDapp/card5.jpg";
import Card6 from "../../assets/imgs/theDefilyDapp/card6.jpg";
import Card1Mobil from "../../assets/imgs/theDefilyDapp/card1Mobil.jpg";
import Card2Mobil from "../../assets/imgs/theDefilyDapp/card2Mobil.jpg";
import Card3Mobil from "../../assets/imgs/theDefilyDapp/card3Mobil.jpg";
import Card4Mobil from "../../assets/imgs/theDefilyDapp/card4Mobil.jpg";
import Card5Mobil from "../../assets/imgs/theDefilyDapp/card5Mobil.jpg";
import Card6Mobil from "../../assets/imgs/theDefilyDapp/card6Mobil.jpg";
import ButtonGetStarted from "../generals/ButtonGetStarted";

const DefilyDAPP = () => {
  const { t } = useTranslation();

  const listCardsMobil = [
    {
      id: 1,
      title: t("Web Innovation"),
      fondoImg: Card1,
      fondoImgMobil: Card1Mobil,
      description: t("The most complete DAPP in the world"),
    },
    {
      id: 2,
      title: t("Decentralized Crypto Staking"),
      fondoImg: Card2,
      fondoImgMobil: Card2Mobil,
      description: t("With the DeFily Staking Program is second to none generating consistent monthly"),
    },
    {
      id: 3,
      title: t("Automated Residual Rewards"),
      fondoImg: Card3,
      fondoImgMobil: Card3Mobil,
      description: t("The DeFily Affiliate Program is fully decentralized and managed by smart contracts"),
    },
    {
      id: 4,
      title: t("Bonuses and Incentives"),
      fondoImg: Card4,
      fondoImgMobil: Card4Mobil,
      description: t("As you grow your DeFily business"),
    },
    {
      id: 5,
      title: t("Blockchain Based Community Governance"),
      fondoImg: Card5,
      fondoImgMobil: Card5Mobil,
      description: t("The more rewards you accumulate in your DeFily business"),
    },
    {
      id: 6,
      title: t("Lucrative Affiliate Program"),
      fondoImg: Card6,
      fondoImgMobil: Card6Mobil,
      description: t("The DeFily affiliate program rewards you instantly via smart contracts"),
    },
  ];

  const listCards = [
    {
      id: 1,
      title: t("Web Innovation"),
      fondoImg: Card1,
      fondoImgMobil: Card1Mobil,
      description: t("The most complete DAPP in the world"),
    },
    {
      id: 2,
      title: t("Decentralized Crypto Staking"),
      fondoImg: Card2,
      fondoImgMobil: Card2Mobil,
      description: t("With the DeFily Staking Program is second to none generating consistent monthly"),
    },
    {
      id: 3,
      title: t("Automated Residual Rewards"),
      fondoImg: Card3,
      fondoImgMobil: Card3Mobil,
      description: t("The DeFily Affiliate Program is fully decentralized and managed by smart contracts"),
    },
  ];

  const listCards2 = [
    {
      id: 4,
      title: t("Bonuses and Incentives"),
      fondoImg: Card4,
      fondoImgMobil: Card4Mobil,
      description: t("As you grow your DeFily business"),
    },
    {
      id: 5,
      title: t("Blockchain Based Community Governance"),
      fondoImg: Card5,
      fondoImgMobil: Card5Mobil,
      description: t("The more rewards you accumulate in your DeFily business"),
    },
    {
      id: 6,
      title: t("Lucrative Affiliate Program"),
      fondoImg: Card6,
      fondoImgMobil: Card6Mobil,
      description: t("The DeFily affiliate program rewards you instantly via smart contracts"),
    },
  ];

  return (
    <div className=" bg-[#0E0E33] px-6 lg:px-[100px] pt-8 lg:pt-[60px] pb-8 lg:pb-0 h-auto lg:h-[690px] relative">
      <img src={arosDark} alt="Aros" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[500px]" />
      <h1 className="text-[24px] lg:text-[32px] font-bold bg-gradient-to-r from-[#DBDFE4] to-[#8C8F91] text-transparent bg-clip-text mb-4 lg:mb-12 text-center">
        {t("The DeFily DAPP & DAO")}
      </h1>

      <div className="justify-between hidden lg:flex">
        <div>
          {listCards.map((card, index) => (
            <div
              key={card.id}
              className={`group relative w-[255px] h-[140px] overflow-hidden rounded-2xl p-4 aspect-[4/3] transition-all duration-300 mb-6 last:mb-0  ${
                index % 2 === 1 ? "ml-8" : "ml-0"
              }`}
            >
              <div
                className="absolute inset-0 bg-cover bg-center transition-all duration-300"
                style={{
                  backgroundImage: `url(${card.fondoImg})`,
                }}
              />
              <div className="absolute inset-0 bg-purple-600/0 group-hover:bg-[#7A2FF4] transition-all duration-300 flex items-center justify-center w-[255px] h-[140px]">
                <div>
                  <h3 className="text-[18px] font-bold text-white group-hover:opacity-0 transition-opacity duration-300 absolute left-4 bottom-4">
                    {card.title}
                  </h3>
                  <p className="text-white text-[12px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%]">
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center justify-between z-10">
          <ButtonGetStarted text={t("Get Started")} linkRedirect="https://app.defily.ai/" />
          <img src={Phones} alt="Phones" className="w-[460px] h-[465px] mt-[15px]" />
        </div>

        <div className="flex flex-col items-end">
          {listCards2.map((card, index) => (
            <div
              key={card.id}
              className={`group relative w-[255px] h-[140px] overflow-hidden rounded-2xl p-4 aspect-[4/3] transition-all duration-300 mb-6 last:mb-0  ${
                index % 2 === 1 ? "mr-8" : "mr-0"
              }`}
            >
              <div
                className="absolute inset-0 bg-cover bg-center transition-all duration-300"
                style={{
                  backgroundImage: `url(${card.fondoImg})`,
                }}
              />
              <div className="absolute inset-0 bg-purple-600/0 group-hover:bg-[#7A2FF4] transition-all duration-300 flex items-center justify-center w-[255px] h-[140px]">
                <div>
                  <h3 className="text-[18px] font-bold text-white group-hover:opacity-0 transition-opacity duration-300 absolute left-4 bottom-4">
                    {card.title}
                  </h3>
                  <p className="text-white text-[12px] opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%]">
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col items-center justify-between lg:hidden">
        {listCardsMobil.map((card, index) => (
          <div
            key={card.id}
            className={`group relative w-[92%] h-[185px] lg:h-[140px] overflow-hidden rounded-2xl p-4  mb-6 last:mb-0 ${
              index % 2 === 1 ? "ml-8" : "ml-0"
            }`}
          >
            <div
              className="absolute inset-0 bg-cover bg-center "
              style={{
                backgroundImage: `url(${card.fondoImg})`,
              }}
            />
            <div className="absolute flex items-end justify-center h-[185px] lg:h-[140px] left-4 bottom-4">
              <div>
                <h3 className="text-[16px] lg:text-[18px] font-bold text-white">{card.title}</h3>
                <p className="text-white text-[12px]">{card.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full flex justify-center mt-4 lg:hidden">
        <ButtonGetStarted text={t("Get Started")} linkRedirect="https://app.defily.ai/" />
      </div>
    </div>
  );
};

export default DefilyDAPP;
