import React from "react";
import { useTranslation } from "react-i18next";

const Disclaimer = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-[69px] lg:mt-[75px]">
      <div className="py-8 lg:py-10 px-6 lg:px-[100px] bg-gradient-to-r from-[#20DABB] to-[#1FAC95] text-white text-center">
        <h1 className="text-[24px] lg:text-[32px] font-bold">{t("Disclaimer")}.</h1>
      </div>

      <div className="my-8 lg:my-[80px] mx-6 lg:mx-[100px] pl-0 lg:pl-6 border-none lg:border-l-[2px] lg:border-solid border-[#20DABB] text-[#0E0E33] text-[14px] lg:text-[16px] ">
        <p className="mb-6">
          {t(
            "The information and services provided by DeFily are for informational and educational purposes only and do not constitute financial advice or recommendations"
          )}
        </p>
        <p className="mb-6">
          {t("Cryptocurrency and DeFi trading involve high risk and can lead to substantial financial loss due to market volatility")}
        </p>
        <p className="mb-6">{t("Profits from trading are subject to performance fees charged by DeFily")}</p>
        <p>{t("Any deviation from this official presentation is not considered to be a representation of DeFily")}</p>
      </div>
    </div>
  );
};

export default Disclaimer;
