import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { validateEmail, validateFullName } from "../../utils/value_object_register_steps";
import arosDark from "../../assets/imgs/arosFondoOscuro.png";
import ModalComponent from "../generals/ModalComponent";
import IconClose from "../../assets/icons/close.svg";
import ProcessingIcon from "../../assets/imgs/getInTouchProcessing.gif";
import RechazedIcon from "../../assets/imgs/rechazed-icon.png";

const GetInTouch = () => {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState({
    email: "",
    fullName: "",
  });

  function getValueInputEmail(value) {
    const valueEmail = validateEmail(value);

    setEmail(value);

    if (valueEmail) {
      setFieldError({
        ...fieldError,
        email: valueEmail,
      });
    } else {
      setFieldError({
        ...fieldError,
        email: "",
      });
    }
  }

  function getValueInputFullName(value) {
    let fullNameI = value.replace(/[^A-Za-zñ\s]/g, "");
    const valueFullName = validateFullName(fullNameI);

    setFullName(fullNameI);

    if (valueFullName) {
      setFieldError({
        ...fieldError,
        fullName: valueFullName,
      });
    } else {
      setFieldError({
        ...fieldError,
        fullName: "",
      });
    }
  }

  useEffect(() => {
    const savedData = localStorage.getItem("getInTouchData");
    if (savedData) {
      const { fullName, email } = JSON.parse(savedData);
      setFullName(fullName);
      setEmail(email);
    }
  }, []);

  const handleSubmit = () => {
    setIsProcessing(true);
    setShowModal(true);

    if (error) {
      setIsProcessing(false);
      setIsDeclined(true);
      return;
    }

    if (fullName || email) {
      const dataToSave = { fullName, email };
      localStorage.setItem("getInTouchData", JSON.stringify(dataToSave));
    }

    setTimeout(() => {
      setIsProcessing(false);
      setShowModal(false);
    }, 5000);
  };

  return (
    <div className="mt-8 lg:mt-0 mx-6 lg:mx-[100px] mb-8 lg:mb-[100px] p-6 lg:p-14 bg-[#7A2FF4] rounded-[24px] text-center relative">
      <img src={arosDark} alt="Aros" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[500px] z-0" />
      <h1 className="text-[24px] lg:text-[32px] font-bold text-white">{t("Get in touch with DeFily")}</h1>
      <p className="text-[14px] text-[#DBDFE4] mt-2 lg:mt-4">{t("Leave your name and email and we will contact you directly")}</p>

      <form onSubmit={(e) => e.preventDefault()} className="mt-4 lg:mt-12 relative z-10">
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center ">
          <div className="w-full lg:w-2/6  h-[90px] mr-0 lg:mr-6">
            <label className="block text-[14px] font-bold text-[#DBDFE4] mb-2 text-start">{t("Full Name")}</label>
            <input
              type="text"
              placeholder={t("Full Name")}
              value={fullName}
              className="py-2 px-4 rounded-[8px] border border-solid border-[#DBDFE4] bg-transparent text-[14px] text-[#DBDFE4] w-full"
              onChange={(e) => getValueInputFullName(e.target.value)}
            />
            <p className="textErrorInput text-[#b3261e] text-[12px] mt-[2px]">{fieldError.fullName}</p>
          </div>
          <div className="w-full lg:w-2/6 h-[90px]">
            <label className="block text-[14px] font-bold text-[#DBDFE4] mb-2 text-start">{t("Email")}</label>
            <input
              type="email"
              value={email}
              placeholder={t("example@domain.com")}
              className="py-2 px-4 rounded-[8px] border border-solid border-[#DBDFE4] bg-transparent text-[14px] text-[#DBDFE4] w-full"
              onChange={(e) => getValueInputEmail(e.target.value)}
            />
            <p className="textErrorInput text-[#b3261e] text-[12px] mt-[2px]">{fieldError.email}</p>
          </div>
        </div>

        <button
          type="button"
          onClick={handleSubmit}
          className="bg-white px-5 py-1 rounded-[10px] mt-4 text-[#7A2FF4] text-[16px] font-bold w-[160px] cursor-pointer"
        >
          {t("Send")}
        </button>
      </form>

      <ModalComponent isOpen={showModal} setIsOpen={setShowModal} classBody="bg-white w-[280px] h-auto rounded-[20px] shadow-lg">
        <div className="container-icon-close cursor-pointer w-6 absolute top-3 right-3" onClick={() => setShowModal(false)}>
          <img src={IconClose} alt="Close Icon" />
        </div>

        {isProcessing && (
          <div className="w-full h-full flex flex-col items-center justify-center p-4">
            <div>
              <img src={ProcessingIcon} alt="processing" className="w-[132px] h-[132px]" />
            </div>
            <p className="my-5 text-[16px] text-[#554D77] text-center font-bold">{t("You are one step closer to being part of DeFily")}</p>
            <p className="text-[14px] text-[#554D77] text-center">{t("You will soon receive an email with everything you need to know about us")}</p>
          </div>
        )}

        {isDeclined && (
          <div className="w-full h-full flex flex-col items-center justify-center p-4">
            <div>
              <img src={RechazedIcon} alt="Decline" />
            </div>
            <p className="my-5 text-[16px] text-[#554D77] text-center font-bold">{t("There seems to be an error while registering")}</p>
            <p className="text-[14px] text-[#554D77] text-center">{t("Please try again or contact our support team")}</p>
          </div>
        )}
      </ModalComponent>
    </div>
  );
};

export default GetInTouch;
