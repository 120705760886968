import { isEmpty, isString, isValidDate, isValidEmail, onlyLetters } from "./validations";

function validateEmail(value) {
  let MAX_CHARACTER = 50;
  let MIN_CHARACTER = 13;

  if (isEmpty(value)) {
    return "Email is required";
  }
  if (!isString(value)) {
    return "Email must be a string";
  }
  if (!isValidEmail(value)) {
    return "Email must be a valid email";
  }
  if (value.length > MAX_CHARACTER || value.length < MIN_CHARACTER) {
    return "The email value must be less than 50 and greater than 13";
  }
}

function validateFullName(value) {
  let MAX_CHARACTER = 50;
  let MIN_CHARACTER = 4;

  if (isEmpty(value)) {
    return "Full name is required";
  }
  if (!isString(value)) {
    return "The full name value must be a string";
  }
  if (!onlyLetters(value)) {
    return "The full name is not valid";
  }
  if (value.length > MAX_CHARACTER || value.length < MIN_CHARACTER) {
    return "The full name value must be less than 50 and greater than 4";
  }
}

function validateTransmission(value) {
  if (isEmpty(value)) {
    return "Transmission is required";
  }
}

export { validateEmail, validateFullName, validateTransmission };
