import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import FlechaDown from "../assets/icons/downButton.svg";

const ContainerLanguage = () => {
  const submenuRef = useRef(null);
  const [isOpenMenuLanguage, setIsOpenMenuLanguage] = useState(false);

  // funcion  para cerrar el menu de idiomas cuando se haga click fuera de el
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (submenuRef.current && !submenuRef.current.contains(event.target)) {
        setIsOpenMenuLanguage(false);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpenMenuLanguage]);

  function openMenuLanguage() {
    setIsOpenMenuLanguage(!isOpenMenuLanguage);
  }
  const { i18n } = useTranslation("");

  function changeLanguage(type) {
    localStorage.setItem("language", type);
    i18n.changeLanguage(type);
  }
  // console.log(i18n)
  const languageList = [
    {
      name: "English",
      type: "en",
    },
    {
      name: "Español",
      type: "es",
    },
    {
      name: "Français",
      type: "fr",
    },
    {
      name: "Italian",
      type: "it",
    },
    {
      name: "Português",
      type: "pt",
    },
    {
      name: "Arabic",
      type: "ar",
    },
    {
      name: "Persian",
      type: "fa",
    },
    {
      name: "Hindi",
      type: "hi",
    },
    {
      name: "Japanese",
      type: "ja",
    },
    {
      name: "Korean",
      type: "ko",
    },
    {
      name: "Russian",
      type: "ru",
    },
    {
      name: "Thai",
      type: "th",
    },
    {
      name: "Turkish",
      type: "tr",
    },
    {
      name: "Urdu",
      type: "ur",
    },
    {
      name: "Vietnamese",
      type: "vi",
    },
    {
      name: "Chinese",
      type: "zh",
    },
  ];

  let ImagenesObj = {
    es: "ESP",
    en: "ENG",
    fr: "FRA",
    it: "ITA",
    pt: "POR",
    ar: "ARA",
    fa: "FAS",
    hi: "HIN",
    ja: "JPN",
    ko: "KOR",
    ru: "RUS",
    th: "THA",
    tr: "TUR",
    ur: "URD",
    vi: "VIE",
    zh: "ZHO",
  };

  return (
    <>
      <div className="container-idiomas relative " ref={submenuRef}>
        <button className="button-idiomas flex items-center text-sm" onClick={openMenuLanguage}>
          <div className="boxbandera border-solid border-[1px] border-[#AD98FF] px-2 lg:px-4 rounded-[10px] w-[45px] lg:w-[75px]">
            <p className="textGray font-light pt-[2px] lg:pt-[4px] pb-[1px] w-full text-[12px] lg:text-[16px]">{ImagenesObj[i18n.language]}</p>
          </div>
        </button>
        <div className={`list-idiomas ${isOpenMenuLanguage ? "show" : "hidden"}`}>
          <ul className="absolute space-y-1 -left-20 lg:-left-14 top-[45px] lg:top-[60px] bg-white w-[200px] h-[300px] overflow-y-scroll shadow-md text-center rounded-b-md montserrat-regular">
            {languageList
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((pais, index) => (
                <li
                  className="container-bandera cursor-pointer text-[16px] text-black hover:text-[#7A2FF4] hover:font-bold py-4 px-6 border-b-[2px] border-solid border-[#F2F3F8] last:border-b-0"
                  key={index}
                  onClick={() => {
                    changeLanguage(pais.type);
                    openMenuLanguage();
                  }}
                >
                  {pais.name}
                </li>
              ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ContainerLanguage;
