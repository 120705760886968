"use client";
import React from "react";
import IconCloseSVG from "../../assets/icons/close.svg";
import Modal from "react-modal";
Modal.setAppElement(document.getElementById("root"));

const ModalComponent = ({ children, isOpen, setIsOpen, classBody }) => {
  return (
    <Modal
      isOpen={isOpen}
      overlayClassName={"z-50 fixed top-0 left-0 right-0 bottom-0 backdrop-blur-sm"}
      className={`w-auto h-auto textThemeOne backgroundThemeOne borderThemeOne absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 border rounded-md`}
    >
      <div className={`relative ${classBody}`}>
        <div className="flex justify-end">{/* <IconCloseSVG setIsOpen={setIsOpen} className="absolute top-4 right-4" /> */}</div>
        {children}
      </div>
    </Modal>
  );
};

export default ModalComponent;
