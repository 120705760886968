import { useState, useEffect } from "react";
import ButtonGetStarted from "../generals/ButtonGetStarted";
import { useTranslation } from "react-i18next";
import "./banner.css";
import ImageBanner1 from "../../assets/imgs/banner1.png";
import ImageBanner2 from "../../assets/imgs/banner2.png";
import ImageBanner3 from "../../assets/imgs/banner3.png";

export default function Banners() {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: t("The best technology"),
      subtitle: t("Empower your staking with our decentralized finance platform"),
      image: ImageBanner1,
    },
    {
      title: t("Go further"),
      subtitle: t("Maximize the performance of your cryptoassets through advanced staking"),
      image: ImageBanner2,
    },
    {
      title: t("Join DeFily"),
      subtitle: t("Get started now and discover the future of finance"),
      image: ImageBanner3,
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % slides.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="container-banner relative overflow-hidden mt-[65px] lg:mt-0">
      <div className="h-full flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className="min-w-full h-full flex flex-col-reverse lg:flex-row lg:items-center justify-center lg:justify-between px-6 lg:px-[100px]"
          >
            <div className="w-full lg:w-3/6 mt-6 lg:mt-0">
              <h1
                className={`${
                  slide.title === t("The best technology")
                    ? "bg-gradient-to-r from-[#DBDFE4] to-[#8C8F91]"
                    : slide.title === t("Go further")
                    ? "bg-gradient-to-r from-[#20DABB] to-[#1FAC95]"
                    : "bg-gradient-to-r from-[#AD98FF] to-[#612DFE]"
                } text-[36px] lg:text-[48px] text-center lg:text-start font-bold text-transparent bg-clip-text`}
              >
                {slide.title}.
              </h1>
              <p className="text-[16px] lg:text-[18px] text-center lg:text-start text-white mt-6">{slide.subtitle}.</p>

              <div
                className={`flex w-[200px] h-1 rounded-[100px] mx-auto lg:mx-0 my-6 ${
                  slide.title === t("Join DeFily") ? "bg-gradient-to-t from-[#AD98FF] to-[#612DFE]" : "bg-gradient-to-t from-[#0E0E33] to-[#0E0E33]"
                }`}
              >
                <div
                  className={`rounded-s-[10px] ${
                    slide.title === t("The best technology")
                      ? "bg-gradient-to-t from-[#AD98FF] to-[#612DFE] w-2/6"
                      : slide.title === t("Go further")
                      ? "bg-gradient-to-t from-[#AD98FF] to-[#612DFE] w-2/6"
                      : "bg-gradient-to-t from-[#AD98FF] to-[#612DFE] 2/6"
                  }`}
                ></div>
                <div
                  className={` ${
                    slide.title === t("The best technology")
                      ? "bg-gradient-to-t from-[#0E0E33] to-[#0E0E33] w-2/6"
                      : slide.title === t("Go further")
                      ? "bg-gradient-to-t from-[#AD98FF] to-[#612DFE] w-2/6"
                      : "bg-gradient-to-t from-[#AD98FF] to-[#612DFE] 2/6"
                  }`}
                ></div>
                <div
                  className={`rounded-e-[10px] ${
                    slide.title === t("The best technology")
                      ? "bg-gradient-to-t from-[#0E0E33] to-[#0E0E33] w-2/6"
                      : slide.title === t("Go further")
                      ? "bg-gradient-to-t from-[#0E0E33] to-[#0E0E33] w-2/6"
                      : "bg-gradient-to-t from-[#AD98FF] to-[#612DFE] 2/6"
                  }`}
                ></div>
              </div>

              <div className="w-full text-center lg:text-start">
                <ButtonGetStarted text={t("Get Started")} linkRedirect="https://app.defily.ai/" classContainer="border-none" />
              </div>
            </div>

            <div className="w-full lg:w-3/6 flex justify-center lg:justify-end">
              <div className="h-[320px] w-[320px] lg:h-[480px] lg:w-[480px]">
                <img src={slide.image} alt={`Slide ${index + 1}`} className="w-full  lg:h-full object-cover" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
