import React from "react";
import { useTranslation } from "react-i18next";
import LearnPlayIcon from "../../assets/icons/learnMorePlay.svg";

const LearnMore = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-[#0E0E33] h-[430px] lg:h-[870px] relative">
      <div className="h-1/2 px-6 lg:px-[100px]">
        <div className="flex flex-col lg:flex-row items-center justify-center pt-4 lg:pt-12 mb-0 lg:mb-4">
          <img src={LearnPlayIcon} alt="Learn Play Icon" className="w-[40px] h-[40px]" />
          <h1 className="text-[24px] lg:text-[32px] font-bold bg-gradient-to-r from-[#DBDFE4] to-[#8C8F91] text-transparent bg-clip-text ml-0 lg:ml-2 text-center">
            {t("Learn more about DeFily")}
          </h1>
        </div>
        <p className="text-[14px] text-white text-center mb-4 lg:mb-12">{t("In this video we tell you everything")}</p>
      </div>
      <div className="bg-white h-1/2 w-full px-6 lg:px-[100px] pb-8 lg:pb-[100px]">
        <div className="w-[87%] lg:w-[935px] h-[235px] lg:h-[575px] rounded-[10px] aspect-video overflow-hidden mx-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-12">
          <iframe
            src="https://www.youtube.com/embed/GznmHjS90M4"
            className="w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
