import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/Logo.png";
import iconTelegram from "../../assets/icons/Telegram.svg";
import iconTwitter from "../../assets/icons/X.svg";
import iconFacebook from "../../assets/icons/Facebook.svg";
import iconInstagram from "../../assets/icons/Instagram.svg";
import iconLinkedin from "../../assets/icons/LinkedIn.svg";
import iconTiktok from "../../assets/icons/tiktok.svg";
import iconYoutube from "../../assets/icons/YouTube.svg";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useRef } from "react";

const Footer = () => {
  const { t } = useTranslation();

  // Tawk Messenger
  const propertyId = "66a38ce632dca6db2cb61037";
  const widgetId = "1i3ndspjb";
  const tawkMessengerRef = useRef();

  const listRedes = [
    {
      name: "telegram",
      img: iconTelegram,
      link: "https://t.me/defilyai",
    },
    {
      name: "instagram",
      img: iconInstagram,
      link: "https://www.instagram.com/defily.ai",
    },
    {
      name: "facebook",
      img: iconFacebook,
      link: "https://www.facebook.com/share/RD77vK1C4jpcJs49/?mibextid=LQQJ4d",
    },
    {
      name: "twitter",
      img: iconTwitter,
      link: "https://x.com/defilyai",
    },
    {
      name: "linkedin",
      img: iconLinkedin,
      link: "https://www.linkedin.com/company/defily-ai/",
    },
    {
      name: "tiktok",
      img: iconTiktok,
      link: "https://www.tiktok.com/@defily.ai",
    },
    {
      name: "youtube",
      img: iconYoutube,
      link: "https://youtube.com/@defily?si=1KoT0A27gZBmRH8f",
    },
  ];

  return (
    <div className={`footer bg-black lg:bg-[#1F1159] px-6 lg:px-[60px] pt-6 lg:pt-[40px] pb-[100px]`}>
      <div className="container-up mt-0 lg:mt-6 text-white text-[14px] grid grid-cols-1 lg:grid-cols-3">
        <div className="container1 container-nosotros flex justify-center lg:justify-start mb-8 lg:mb-0">
          <div className="container-centrado text-center lg:text-start">
            <h2 className="font-bold text-center lg:text-start mb-2">{t("About Us")}</h2>
            <Link to={"/disclaimer"}>
              <p>{t("Disclaimer")}</p>
            </Link>
            <Link to={"/termAndConditions"}>
              <p className="my-2">{t("Terms and Conditions")}</p>
            </Link>
            <Link to={"/privacyPolicy"}>
              <p>{t("Privacy Policy")}</p>
            </Link>
          </div>
        </div>

        <div className="container-2 order-last lg:order-none mt-8 lg:mt-0">
          <div className="container-img-logo flex justify-center">
            <img src={logo} alt="" className="w-[160px] h-[40px]" />
          </div>
          <p className="bg-gradient-to-r from-[#DBDFE4] to-[#8C8F91] text-transparent bg-clip-text text-[16px] mt-2 mb-6 text-center">
            {t("YOUR GATEWAY TO WEB3 AND DEFI")}
          </p>
          <div className="container-redes ">
            <div className="container-center flex items-center justify-center">
              {listRedes.map((redSocial, index) => {
                return (
                  <div className="container-img-redSocial mr-4 last:mr-0" key={index}>
                    <a href={redSocial.link} target="_blank" rel="noreferrer">
                      <img src={redSocial.img} alt={redSocial.name} />
                    </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="container-3 flex flex-row lg:flex-col justify-between lg:justify-center">
          <div className="container-contactanos text-center lg:text-end">
            <h2 className="font-bold ">{t("Contact Us")}</h2>
            <a href="mailto:hello@defily.io" className="montserrat-regular">
              info@defily.ai
            </a>
          </div>

          <div className="container-soporte mt-0 lg:mt-6 text-center lg:text-end">
            <h2 className="font-bold">{t("Support")}</h2>
            <a href="mailto:support@defily.ai" className="montserrat-regular">
              support@defily.ai
            </a>
          </div>
        </div>
      </div>
      <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} ref={tawkMessengerRef} className="componentChatTawk" />
    </div>
  );
};

export default Footer;
