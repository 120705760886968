import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import arosDark from "../../assets/imgs/arosFondoOscuro.png";
import Captures from "../../assets/imgs/capturesTvTransmissions.png";
import IconClose from "../../assets/icons/close.svg";
import ModalComponent from "../generals/ModalComponent";
import ProcessingIcon from "../../assets/imgs/tvModalTransmissions.gif";
import RechazedIcon from "../../assets/imgs/rechazed-icon.png";
import { validateEmail, validateFullName, validateTransmission } from "../../utils/value_object_register_steps";

const RegisterTransmissions = () => {
  const { t } = useTranslation();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedTransmission, setSelectedTransmission] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeclined, setIsDeclined] = useState(false);
  const [error, setError] = useState(false);
  const [fieldError, setFieldError] = useState({
    email: "",
    fullName: "",
    transmission: "",
  });

  function getValueInputEmail(value) {
    const valueEmail = validateEmail(value);

    setEmail(value);

    if (valueEmail) {
      setFieldError({
        ...fieldError,
        email: valueEmail,
      });
    } else {
      setFieldError({
        ...fieldError,
        email: "",
      });
    }
  }

  function getValueInputFullName(value) {
    let fullNameI = value.replace(/[^A-Za-zñ\s]/g, "");
    const valueFullName = validateFullName(fullNameI);

    setFullName(fullNameI);

    if (valueFullName) {
      setFieldError({
        ...fieldError,
        fullName: valueFullName,
      });
    } else {
      setFieldError({
        ...fieldError,
        fullName: "",
      });
    }
  }

  const getValueInputTransmission = (value) => {
    const valueTransmission = validateTransmission(value);

    setSelectedTransmission(value);

    if (valueTransmission) {
      setFieldError({
        ...fieldError,
        transmission: valueTransmission,
      });
    } else {
      setFieldError({
        ...fieldError,
        transmission: "",
      });
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("transmissionData");
    if (savedData) {
      const { fullName, email, selectedTransmission } = JSON.parse(savedData);
      setFullName(fullName);
      setEmail(email);
      setSelectedTransmission(selectedTransmission);
    }
  }, []);

  const handleSubmit = () => {
    setIsProcessing(true);
    setShowModal(true);

    if (error) {
      setIsProcessing(false);
      setIsDeclined(true);
      return;
    }

    if (fullName || email || selectedTransmission) {
      const dataToSave = { fullName, email, selectedTransmission };
      localStorage.setItem("transmissionData", JSON.stringify(dataToSave));
    }

    setTimeout(() => {
      setIsProcessing(false);
      setShowModal(false);
    }, 5000);
  };

  return (
    <div className="pb-8 lg:pb-[100px]  border-b-[2px] border-solid border-[#F2F3F8] mx-6 lg:mx-[100px]">
      <div className="bg-gradient-to-t from-[#0E0E33] to-[#39307B] p-6 lg:p-14 rounded-[24px] relative">
        <img src={arosDark} alt="Aros" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[525px] z-0" />
        <div className="text-white text-center">
          <h1 className="text-[22px] lg:text-[28px] font-bold mb-2 lg:mb-4">{t("Learn all about DeFily in our exclusive DeFilyTV transmissions")}</h1>
          <p className="text-[14px]">{t("Register here and you will automatically be invited to our next transmission")}</p>
        </div>

        <div className="mt-4 lg:mt-12 relative z-10">
          <form className="w-full lg:w-2/5" onSubmit={(e) => e.preventDefault()}>
            <div className="w-full h-[90px]">
              <label className="block text-[14px] font-bold text-[#DBDFE4] mb-2">{t("Full Name")}</label>
              <input
                type="text"
                placeholder={t("Full Name")}
                value={fullName}
                className="py-2 px-4 rounded-[8px] border border-solid border-[#DBDFE4] bg-transparent text-[14px] text-[#DBDFE4] w-full"
                onChange={(e) => getValueInputFullName(e.target.value)}
              />
              <p className="textErrorInput text-[#b3261e] text-[12px] mt-[2px]">{fieldError.fullName}</p>
            </div>
            <div className=" w-full h-[90px]">
              <label className="block text-[14px] font-bold text-[#DBDFE4] mb-2">{t("Email")}</label>
              <input
                type="email"
                value={email}
                placeholder={t("example@domain.com")}
                className="py-2 px-4 rounded-[8px] border border-solid border-[#DBDFE4] bg-transparent text-[14px] text-[#DBDFE4] w-full"
                onChange={(e) => getValueInputEmail(e.target.value)}
              />
              <p className="textErrorInput text-[#b3261e] text-[12px] mt-[2px]">{fieldError.email}</p>
            </div>
            <div className="w-full h-[90px]">
              <label className="block text-[14px] font-bold text-[#DBDFE4] mb-2">{t("Select transmission")}</label>
              <select
                className="py-2 px-4 rounded-[8px] border border-solid border-[#DBDFE4] bg-transparent text-[14px] text-[#DBDFE4] w-full cursor-pointer"
                onChange={(e) => getValueInputTransmission(e.target.value)}
              >
                <option value={t("Select transmission")}>{t("Select transmission")}</option>
                <option value={"transmission 1"}>{t("Transmission")} 1</option>
                <option value={t("transmission 2")}>{t("Transmission")} 2</option>
                <option value={t("transmission 3")}>{t("Transmission")} 3</option>
              </select>
              <p className="textErrorInput text-[#b3261e] text-[12px] mt-[2px]">{fieldError.transmission}</p>
            </div>

            <div className="w-full text-center lg:text-start">
              <button
                type="button"
                onClick={handleSubmit}
                className="bg-white px-5 py-1 rounded-[10px] mt-4 text-[#7A2FF4] text-[16px] font-bold w-[160px] cursor-pointer"
              >
                {t("Send")}
              </button>
            </div>
          </form>

          <div className="w-3/5 hidden lg:block">
            <img src={Captures} alt="List of captures" className="w-[625px] h-[490px] absolute top-[-11px] right-[-77px]" />
          </div>
        </div>
      </div>

      <ModalComponent isOpen={showModal} setIsOpen={setShowModal} classBody="bg-white w-[280px] h-auto rounded-[20px] shadow-lg">
        <div className="container-icon-close cursor-pointer w-6 absolute top-3 right-3" onClick={() => setShowModal(false)}>
          <img src={IconClose} alt="Close Icon" />
        </div>

        {isProcessing && (
          <div className="w-full h-full flex flex-col items-center justify-center p-4">
            <div>
              <img src={ProcessingIcon} alt="processing" className="w-[132px] h-[132px]" />
            </div>
            <p className="my-5 text-[16px] text-[#554D77] text-center font-bold">{t("You are now part of our next broadcast")}</p>
            <p className="text-[14px] text-[#554D77] text-center">{t("Check your email to see all the information")}</p>
          </div>
        )}

        {isDeclined && (
          <div className="w-full h-full flex flex-col items-center justify-center p-4">
            <div>
              <img src={RechazedIcon} alt="Decline" />
            </div>
            <p className="my-5 text-[16px] text-[#554D77] text-center font-bold">{t("There seems to be an error while registering")}</p>
            <p className="text-[14px] text-[#554D77] text-center">{t("Please try again or contact our support team")}</p>
          </div>
        )}
      </ModalComponent>
    </div>
  );
};

export default RegisterTransmissions;
